export default (app) => {
  // 只能输入正整数
  app.directive('input-number', {
    mounted(el) {
      let dom = el;
      let tempEl = el;
      if (el.nodeName === 'DIV') {
        dom = el.querySelector('.el-input__inner') || el.queryselector('.el-textarea__inner') || el;
      }
      dom.addEventListener('input', () => {
        if (dom.value !== '') {

          dom.value = dom.value.replace(/\D/g, '');
        }
      })

      dom.addEventListener('blur', () => {
        // el-input 使用了compositionstart 限制中文输入
        dom.dispatchEvent(new Event("compositionend"));
        // 重新触发input刷新v-model的值
        dom.dispatchEvent(new Event("change"));
      })
    }
  })
  // 小数位数
  app.directive('input-decimal', {
    mounted(el, binding) {
      let dom = el;

      if (el.nodeName === 'DIV') {
        dom = el.querySelector('.el-input__inner') || el.queryselector('.el-textarea__inner') || el;
      }
      let oldValue = '';
      let reg = new RegExp('^(([1-9]\\d{0,20})|0)(\\.\\d{1,' + (binding.value || 20) + '}|\\.)?$');
      dom.addEventListener('input', function () {
        if (dom.value !== '') {
          if (!reg.test(dom.value)) {
            dom.value = oldValue;
          } else {
            oldValue = dom.value;
          }
        }
      })
      dom.addEventListener('blur', function () {
        // el-input 使用了compositionstart 限制中文输入
        dom.dispatchEvent(new Event("compositionend"));
        // 重新触发input刷新v-model的值
        dom.dispatchEvent(new Event("input"));
      })
    }
  })
  app.directive('perms', {
    mounted(el, binding) {
      if (!permsJudge(binding.value)) {
        el.parentNode.removeChild(el);
      }
      function permsJudge(value) {
        if (localStorage.getItem('permissionList')) {
          let perms = JSON.parse(localStorage.getItem('permissionList'))
          for (let item of perms) {
            if (item === value) {
              return true;
            }
          }
          return false;
        }
      }
    }
  })
}
