export default{
    path:`version-manage`,
    component:()=>import("@/views/version-manage"),
    meta:{
        title: '版本管理',
    },
    children:[
        {
            path:`version-list`,
            component:()=>import("@/views/version-manage/version-list"),
            meta:{
                title:'版本管理'
            }
        },
    ]
}