import {ElLoading} from 'element-plus';
import 'element-plus/theme-chalk/el-loading.css';


let loadingOption = {
  loading: null,
  timeout: 0,
  timer: null
}

/**
 * loading
 * @param option:{
 *   message: '显示的内容',
 *   className: '作用的区域的类名',
 *   lazy: '延时显示时间(s)',
 * }
 */
export function showLoading(option = {}) {
  const {message = '加载中', className = 'main-box', lazy = 280} = option;
  loadingOption.timeout = lazy;
  hideLoading();

  let dialogs = document.getElementsByClassName('el-dialog__wrapper');
  let targetDialog = null;
  if (dialogs) {
    for (let i = 0; i < dialogs.length; i++) {
      if (dialogs[i].style.display !== 'none') {
        targetDialog = dialogs[i];
      }
    }
  }

  let target = targetDialog || document.querySelector('.' + className) || document.body;
  loadingOption.timer = setTimeout(() => {
    loadingOption.loading = ElLoading.service({
      target,
      lock: true,
      text: message,
      background: 'rgba(0, 0, 0, 0.7)',
    })
  }, lazy)
}

export function hideLoading() {
  loadingOption.timer && clearTimeout(loadingOption.timer);
  loadingOption.loading && loadingOption.loading.close();
}
