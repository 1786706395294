import {get, post} from "@/utils/request";
export function login(data) {
    return post('/login/login/admin', data);
}
export function routersList(data) {
    // return get('/adminuser/systemUser/getRouters',data);
    return get('/adminuser/systemUser/info',data);
}
export function cityTree() {
  return get('/base/area/tree')
}
