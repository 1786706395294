import { createStore } from "vuex";
// import userStore from "@/store/userStore";
// import orderStore from "@/store/orderStore";
import createPersistedState from "vuex-persistedstate";
const userStore =  createPersistedState({
    key:'userStore',
    storage: window.localStorage,//window.localStorage
    // paths: ['tel','userId','token','username','userAllPower','newToken']
    path:['token','newToken','expires','tokenInfo','userId','menuData']
})
const orderStore =  createPersistedState({
    key:'orderStore',
    storage: window.localStorage,//window.localStorage
    paths: ['orderInfo']
})

export default createStore({
    state: {
        isAlertNoLogin: false, // 已经弹窗了登录过期的提醒
        tel: '',
        userId: '',
        token: '',
        newToken:"",
        tokenInfo:"",
        username: '',
        expires:"",
        orderInfo: {},
        userAllPower: [],
        menuData:[],

    },
    mutations: {
        setMenuData(state, value){
            state.menuData=value
        },
        setTokenInfo(state, value){
            state.tokenInfo=value
        },
        setIsAlertNoLogin(state, value) {
            state.isAlertNoLogin = value;
        },
        setTel(state, data) {
            state.tel = data;
        },
        setUserId(state, data) {
            state.userId = data;
        },
        setToken(state, data) {
            state.token = data;
        },
        setExpires(state, data) {
            state.expires = data;
        },
        setNewToken(state, data) {
            state.newToken = data;
        },
        setUsername(state, data) {
            state.username = data;
        },
        setUserAllPower(state, data) {
            state.userAllPower = data;
        },
        setOrderInfo(state, data) {
            state.orderInfo = data;
        },
    },
    actions: {},
    // modules: {
    //     userStore,
    //     orderStore
    // },

    plugins: [userStore,orderStore
        // createPersistedState({
        //     reducer: modules => {
        //         return {
        //             userStore: modules.userStore,
        //             orderStore: modules.orderStore
        //         }
        //     }
        // })
    ]
})