export default {
  path: `finance-manage`,
  component: () => import('@/views/finance-manage'),
  meta: {
    title: '财务管理'
  },
  children: [
    {
      path: `reflect`,
      component: () => import('@/views/finance-manage/reflect'),
      meta: {
        title: '提现配置'
      }
    },
    //    {
    //     path: `assetManage`,
    //     component: () => import("@/views/finance-manage/assetManage/index"),
    //     meta: {
    //         title: '钱包管理'
    //     }
    // },
    // {
    //     path: `purseManage`,
    //     component: () => import("@/views/finance-manage/purseManage/index"),
    //     meta: {
    //         title: '钱包管理'
    //     }
    // },
    // {
    //     path: `settlement-records`,
    //     component: () => import("@/views/finance-manage/settlement-records"),
    //     meta: {
    //         title: '结算记录'
    //     }
    // },
    {
      path: `settlement-detail`,
      component: () => import('@/views/finance-manage/settlement-detail'),
      meta: {
        title: '结算详情',
        hiddenMenu: true
      }
    }
  ]
}
