export default {
  path: `basic-Settings`,
  component: () => import('@/views/basic-Settings'),
  meta: {
    title: '基础设置'
  },
  children: [
    {
      path: `picture-setting`,
      component: () => import('@/views/basic-Settings/picture-setting'),
      meta: {
        title: '图片配置'
      }
    },
    {
      path: `menu-setting`,
      component: () => import('@/views/basic-Settings/menu-setting'),
      meta: {
        title: '菜单导航配置'
      }
    },
    {
      path: `route-manager`,
      component: () => import('@/views/basic-Settings/route-manager'),
      meta: {
        title: '路由管理'
      }
    },
    {
      path: `shopping`,
      component: () => import('@/views/basic-Settings/shopping'),
      meta: {
        title: '联盟配置'
      }
    },
    {
      path: `interface-configuration`,
      component: () => import('@/views/basic-Settings/interface-configuration'),
      meta: {
        title: '接口配置'
      }
    },
    {
      path: `identity-level`,
      component: () => import('@/views/basic-Settings/identity-level'),
      meta: {
        title: '等级身份配置'
      }
    },
    {
      path: `service-configuration`,
      component: () => import('@/views/basic-Settings/service-configuration'),
      meta: {
        title: '服务配置'
      }
    },
    {
      path: `announcement`,
      component: () => import('@/views/basic-Settings/announcement/index.vue'),
      meta: {
        title: '公告管理'
      }
    },
    {
      path: `serviceSet`,
      component: () => import('@/views//basic-Settings/serviceSet/index.vue'),
      meta: {
        title: '服务设置'
      }
    },
    {
      path: `message`,
      component: () => import('@/views/basic-Settings/message/index.vue'),
      meta: {
        title: '消息管理'
      }
    }
  ]
}
