export default{
    path:`order-manage`,
    component:()=>import("@/views/order-manage"),
    meta:{
        title: '订单管理',
    },
    children:[
        {
            path:`service-list`,
            component:()=>import("@/views/order-manage/service-list"),
            meta:{
                title:'服务订单'
            }
        },
        {
            path:`service-detail`,
            component:()=>import("@/views/order-manage/service-detail"),
            meta:{
                title:'服务订单详情',
                hiddenMenu: true
            }
        },
        // {
        //     path:`mall-orders-list`,
        //     component:()=>import("@/views/order-manage/mall-orders-list"),
        //     meta:{
        //         title:'商城订单'
        //     }
        // },
        {
            path:`mall-detail`,
            component:()=>import("@/views/order-manage/mall-detail"),
            meta:{
                title:'商城订单详情',
                hiddenMenu: true
            }
        },
        
    ]
}