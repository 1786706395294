export default {
    path: `protocol-manage`,
    component: () => import("@/views/protocol-manage"),
    meta: {
        title: '协议管理',
    },
    children: [
        {
            path: `protocal-list`,
            component: () => import("@/views/protocol-manage/protocal-list"),
            meta: {
                title: '协议管理'
            }
        },
      
    ]
}