export default {
  path: `community-manage`,
  component: () => import('@/views/community-manage'),
  meta: {
    title: '社区管理'
  },
  children: [
    // {
    //     path: `community-list`,
    //     component: () => import("@/views/community-manage/community-list"),
    //     meta: {
    //         title: '社区管理'
    //     }
    // },
    {
      path: `device-list`,
      component: () => import('@/views/community-manage/device-list'),
      meta: {
        title: '设备管理'
      }
    },
    {
      path: `device-manage-detail`,
      component: () => import('@/views/community-manage/device-manage-detail'),
      meta: {
        title: '设备管理详情',
        hiddenMenu: true
      }
    },
    {
      path: `communityManage`,
      component: () => import('@/views/community-manage/communityManage/index.vue'),
      meta: {
        title: '小区管理'
      }
    },
    {
      path: `communityDetails`,
      component: () => import('@/views/community-manage/communityManage/communityDetails'),
      meta: {
        title: '小区管理详情'
      }
    },
    {
      path: `collectionPoint`,
      component: () => import('@/views/community-manage/collectionPoint/index.vue'),
      meta: {
        title: '代收点管理'
      }
    }
  ]
}
