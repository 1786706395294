export default {
    path: `business-manage`,
    component: () => import("@/views/business-manage"),
    meta: {
        title: '业务管理',
    },
    children: [
        {
            path: `opinion`,
            component: () => import("@/views/business-manage/opinion"),
            meta: {
                title: '意见反馈管理'
            }
        },
        // {
        //     path: `coupon`,
        //     component: () => import("@/views/business-manage/coupon"),
        //     meta: {
        //         title: '优惠券管理'
        //     }
        // },
        // {
        //     path: `authentication`,
        //     component: () => import("@/views/business-manage/authentication"),
        //     meta: {
        //         title: '实名认证管理'
        //     }
        // },
    ]
}
