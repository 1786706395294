export default {
    path: `agent-manage`,
    component: () => import("@/views/agent-manage"),
    meta: {
        title: '代理管理',
    },
    children: [
        {
            path: `agent-manage`,
            component: () => import("@/views/agent-manage/agent-manage"),
            meta: {
                title: '代理管理'
            }
        },
        {
            path: `manage-detail`,
            component: () => import("@/views/agent-manage/manage-detail"),
            meta: {
                title: '代理详情',
                hiddenMenu: true
            }
        },
    ]
}