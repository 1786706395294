import {login} from '@/apis/login'
import store from '@/store'
import {hideLoading, showLoading} from '@/utils/loading'
import axios from 'axios'
import {ElMessage, ElMessageBox} from 'element-plus'

let baseURL = process.env.VUE_APP_URL
// if (process.env.NODE_ENV === 'development') {
//   baseURL = baseUrl
// } else {
//   baseURL = process.env.VUE_APP_URL
// }
const instance = axios.create({
  baseURL,
  timeout: 20000
})
const uuid = function () {
  let uuid = ''
  if (window.localStorage.getItem('fsj-uuid')) {
    uuid = window.localStorage.getItem('fsj-uuid')
    return uuid
  }
  const s = []
  const hexDigits = '0123456789abcdef'
  for (let i = 0; i < 36; i++) {
    s[i] = hexDigits.substr(Math.floor(Math.random() * 0x10), 1)
  }
  s[14] = '4' // bits 12-15 of the time_hi_and_version field to 0010
  s[19] = hexDigits.substr((s[19] & 0x3) | 0x8, 1) // bits 6-7 of the clock_seq_hi_and_reserved to 01
  s[8] = s[13] = s[18] = s[23] = '-'

  uuid = s.join('')
  window.localStorage.setItem('fsj-uuid', uuid)
  return uuid
}
const defaultsHeader = {
  'content-type': 'application/json',
  platform: 'web',
  os: navigator.userAgent,
  businessType: 'business',
  sign: 'sign',
  version: '1.0.0',
  appname: 'fsj-admin',
  appType: 'pc',
  iVersion: '1.0.0',
  channelId: '1',
  deviceId: uuid(),
  remark: 'fsj-admin',
  // 'app-id':"bT5LrOgUhmjlF+H/NZt+cA==",
  'app-id': process.env.VUE_APP_ID,
  userId:window.localStorage.getItem('userId'),
  "appId":'8633154445527941167'

}
// 添加请求拦截器
instance.interceptors.request.use(
  config => {
    const userToken = store.state.token
    if (userToken) {
      config.headers['Authorization'] = userToken
    }
    config.headers['l-b-version'] = '1.0.0'
    config.headers = Object.assign(config.headers, defaultsHeader)

    return config
  },
  error => {
    return Promise.reject(error)
  }
)
let downloadList = [
  '/user/equipment/download/template',
  '/user/equipment/download/invalid',
  '/user/equipmentCheck/export',
  '/user/equipmentCode/export',

  '/exam/exam/api/qu/qu/import/template', //试题模板
  '/exam/exam/api/qu/qu/export', //试题
  '/order/shopOrder/export' //商城订单导出
]
// 添加响应拦截器
instance.interceptors.response.use(
  response => {
    // console.log(response.config.url + '\n', response.data);
    let res = response.data
    let code = res.code
    if (response.config.directReturn) {
      return res
    }

    if (Array.isArray(res)) {
      return res
    }
    // 下载的接口直接返回
    if (downloadList.includes(response.config.url)) {
      return Promise.resolve(res)
    }
    let resMsg = res.message || res.msg
    if (code === '100424' || resMsg === '令牌缺失') {
      store.commit('setToken', '')
      if (response.config.url !== '/login/login/admin') {
        let params = {
          loginTypesEnum: 'REFRESH_TOKEN',
          refreshToken: store.state.newToken
        }
        login(params)
          .then(_res => {
            if (_res.code === '100200') {
              store.commit('setToken', _res.resultObj.tokenType + ' ' + _res.resultObj.accessToken)
              store.commit('setNewToken', _res.resultObj.refreshToken)
              store.commit('setTokenInfo', _res.resultObj.accessToken)
              location.reload()
            }
          })
          .catch(e => {
            if (e.code === '500432') {
              ElMessageBox.confirm('登录已经过期，请重新登录', '确认信息', {
                distinguishCancelAndClose: true,
                confirmButtonText: '重新登录',
                cancelButtonText: '取消'
              }).then(() => {
                localStorage.clear()
                sessionStorage.clear()
                window.location.hash = '#/login'
              })
            }
          })
      }
      return Promise.reject(res)
    }

    if (code === '100200' || Array.isArray(res.rows) || res.resultObj || res.id || res.body) {
      return Promise.resolve(res)
    }

    // 直接返回错误
    if (response.config.retErr) {
      return Promise.reject(res)
    } else {
      resMsg && ElMessage.error(resMsg)
      return Promise.reject(res)
    }
  },
  error => {
    hideLoading()
    error && ElMessage.error(error)
    return Promise.reject(error)
  }
)
function handleGetParams(data) {
  let res = ''
  for (const dataKey in data) {
    res += `${dataKey}=${data[dataKey]}&`
  }
  if (!res) return ''
  return `?${res.substr(0, res.length - 1)}`
}
const post = function (url, data, config = {}, isShowLoading = true) {
  return packageReq(isShowLoading, {
    method: 'post',
    url,
    data,
    ...config
  })
}

const postParams = function (url, data, config = {}, isShowLoading = true) {
  return packageReq(isShowLoading, {
    method: 'post',
    url: `${url}${handleGetParams(data)}`,
    ...config
  })
}

const get = function (url, data, config = {}, isShowLoading = true) {
  return packageReq(isShowLoading, {
    method: 'get',
    url: `${url}${handleGetParams(data)}`,
    ...config
  })
}

const put = function (url, data, config = {}, isShowLoading = true) {
  return packageReq(isShowLoading, {
    method: 'put',
    url,
    data,
    ...config
  })
}

const putUrl = function (url, data, config = {}, isShowLoading = true) {
  return packageReq(isShowLoading, {
    method: 'put',
    url: `${url}${handleGetParams(data)}`,
    ...config
  })
}

const deleteReq = function (url, config = {}, isShowLoading = true) {
  return packageReq(isShowLoading, {
    method: 'delete',
    url,
    ...config
  })
}

const deleteBody = function (url, data, config = {}, isShowLoading = true) {
  return packageReq(isShowLoading, {
    method: 'delete',
    url,
    data,
    ...config
  })
}

const deleteParams = function (url, data, config = {}, isShowLoading = true) {
  return packageReq(isShowLoading, {
    method: 'delete',
    url: `${url}${handleGetParams(data)}`,
    ...config
  })
}

function packageReq(isShowLoading, reqConfig) {
  isShowLoading && showLoading()
  let reqPromise = instance.request(reqConfig)
  reqPromise.finally(() => {
    isShowLoading && hideLoading()
  })
  return reqPromise
}
//下面是vue3必须加的，vue2不需要，只需要暴露出去get，post方法就可以
export default {
  install: app => {
    app.config.globalProperties['post'] = post
    app.config.globalProperties['postParams'] = postParams
    app.config.globalProperties['get'] = get
    app.config.globalProperties['put'] = put
    app.config.globalProperties['putUrl'] = putUrl
    app.config.globalProperties['deleteReq'] = deleteReq
    app.config.globalProperties['deleteBody'] = deleteBody
    app.config.globalProperties['deleteParams'] = deleteParams
    app.config.globalProperties['$axios'] = axios
  }
}

export {deleteBody, deleteParams, deleteReq, get, instance, post, postParams, put, putUrl}

