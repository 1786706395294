<template>
  <el-container class="app-container">
    <el-header
      style="background-color: #1875f0; overflow: hidden"
      class="flex-ali-center flex-jus-sp app-header"
    >
      <div class="app-header-logo" style="height: 100%">
        <div class="logo-and-title">
          <img src="@/assets/logo.png" alt="logo" class="app-logo" />
          <span class="app-title">众喜管理后台</span>
        </div>
      </div>
      <div class="flex flex-ai-center" style="height: 100%">
        <el-dropdown style="height: 100%" @command="handleDropdown">
          <span class="el-dropdown-link" style="height: 100%">
            <div class="flex-ali-center user-info">
              <span class="iconfont icon-touxiang user-portrait"></span>
              <div class="username">
                {{ state.userInfo.username || "未登录" }}
              </div>
            </div>
          </span>
          <template #dropdown>
            <el-dropdown-menu>
              <!-- <el-dropdown-item command="a" @click="dropdownClick(0)">
                <i class="el-icon-user"></i>
                个人中心
              </el-dropdown-item>
              <el-dropdown-item command="b" @click="dropdownClick(1)">
                <i class="el-icon-lock"></i>
                修改密码
              </el-dropdown-item> -->
              <el-dropdown-item command="c" @click="dropdownClick(2)">
                <i class="el-icon-switch-button"></i>
                退出登录
              </el-dropdown-item>
            </el-dropdown-menu>
          </template>
        </el-dropdown>
      </div>
    </el-header>
    <el-container class="micro-main-container">
      <!-- :unique-opened="true" -->
      <el-menu
        :unique-opened="true"
        :default-active="currentActive"
        class="aside-scroll"
        :style="{
          height: '100%',
          width: state.isCollapse ? '60px' : '200px',
          transition: 'all 0s',
          borderRight: '2px solid #efefef',
        }"
        router
        :collapse="state.isCollapse"
      >
        <template v-for="item in state.menuData">
          <el-sub-menu :index="item.path" v-if="item.children" :key="item.path">
            <template #title>
              <span class="meta-title">{{ item.meta.title }}</span>
            </template>
            <template v-for="el in item.children">
              <!-- <el-menu-item v-if="el.meta && !el.meta.hiddenMenu" :key="`${item.path}/${el.path}`"
                :index="`/${item.path}/${el.path}`">
                {{ el.meta.title }}
              </el-menu-item> -->
              <el-menu-item
                v-if="el.meta && !el.hidden"
                :key="`${item.path}/${el.path}`"
                :index="`${item.path}/${el.path}`"
              >
                {{ el.meta.title }}
              </el-menu-item>
            </template>
          </el-sub-menu>
          <!-- <el-menu-item v-else :index="`/${item.path}`" :key="item.path">
            {{ item.meta.title }}
          </el-menu-item> -->
          <el-menu-item v-else :index="`${item.path}`" :key="item.path"></el-menu-item>
        </template>
      </el-menu>
      <div class="system-main">
        <!-- :key="route.path" -->
        <router-navs></router-navs>
        <router-view v-slot="{ Component }">
          <!-- <keep-alive> -->
          <component :is="Component" />
          <!-- </keep-alive> -->
        </router-view>
      </div>
    </el-container>
  </el-container>
</template>
<script setup name="index">
import { routersList, cityTree } from "@/apis/login";
import { computed, onMounted, reactive } from "vue";
import { useRoute, useRouter } from "vue-router";
import { useStore } from "vuex";
import { name } from "../../package.json";
import RouterNavs from "../components/RouterNavs.vue";
const route = useRoute();
const router = useRouter();
const store = useStore();

let state = reactive({
  userId: "",
  menuData: [],
  isCollapse: false,
  userInfo: {},
});
const currentActive = computed(() => {
  return route.path.replace(new RegExp(`\\/subsystem\\/${name}`), "");
});
const dropdownClick = (val) => {
  if (val == 2) {
    localStorage.clear();
    sessionStorage.clear();
    router.push("/login");
    store.commit("setToken", "");
  }
};
const getUser = () => {
  if (store.state.tokenInfo != "") {
    let tokenInformStr = store.state.tokenInfo.split(".");
    let tokenInform = JSON.parse(
      decodeURIComponent(
        escape(window.atob(tokenInformStr[1].replace(/-/g, "+").replace(/_/g, "/")))
      )
    );
    let user = JSON.parse(localStorage.getItem("userInfo"));
    state.userInfo = user;
    state.userId = tokenInform.user_info.id;
  }
};
const getRoute = () => {
  routersList({ userId: state.userId }).then((res) => {
    if (res.code === "100200") {
      state.menuData = res.resultObj.routerVoList;
      localStorage.setItem("navList", JSON.stringify(res.resultObj.routerVoList));
      localStorage.setItem("permissionList", JSON.stringify(res.resultObj.permissions));
      localStorage.setItem("userId", res.resultObj.sysUser.userId);
    }
  });
};
const getCityData = () => {
  cityTree().then((res) => {
    if (res.code === "100200") {
      localStorage.setItem("cityData", JSON.stringify(res.resultObj));
    }
  });
};
onMounted(() => {
  getUser();
  getRoute();
  getCityData();
});
</script>
<style lang="scss" scoped>
.aside-scroll {
  overflow-y: scroll;
  overflow-x: hidden;
  border-right: 0;

  &::-webkit-scrollbar {
    width: 6px;
    height: 6px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #e5e4e4;
    border-radius: 4px;
  }
}

.menu-fold {
  text-align: center;
  height: 45px;
  line-height: 45px;
  cursor: pointer;
  font-size: 15px;
  background-color: #f5f5f5;
}

.app-container {
  width: 100%;
  height: 100%;
  min-width: 1200px;
  background-color: #1875f0;
  overflow: hidden;

  .el-menu--horizontal > .el-menu-item {
    border: 0;
    padding: 0 10px;
  }

  .el-menu--horizontal > .el-menu-item.is-active {
    border-bottom: 0;
  }

  .app-header-logo {
    display: flex;
    align-items: center;
  }

  .meta-title {
    font-weight: bold;
  }
}

.logo-and-title {
  width: 220px;
  display: flex;
  align-items: center;
  padding-left: 20px;
}

.app-title {
  font-size: 18px;
  font-weight: bold;
  letter-spacing: 2px;
  color: #ffffff;
}

.app-logo {
  width: 30px;
  height: 30px;
  object-fit: contain;
  margin-right: 10px;
}

.user-portrait {
  font-size: 20px;
  margin-right: 10px;
}

.username {
  font-size: 14px;
  color: #ffffff;
}

.user-info {
  user-select: none;
  cursor: pointer;
  height: 100%;
  padding: 0 15px;

  &:hover {
    background-color: #c7c5c5;
  }
}
</style>
