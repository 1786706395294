// import  __home from './home';
import __agent from './agent'
import __basicSettings from './basic-Settings'
import __business from './business'
import __community from './community'
import __customer from './customer'
import __finance from './finance'
import __order from './order'
import __protocal from './protocal'
import __system from './system'
import __user from './user'
import __version from './version'
// import  __team from './team'

let routerConfig = [
  // __home,
  __basicSettings,
  __user,
  __order,
  __community,
  __business,
  __agent,
  __finance,
  __version,
  __protocal,
  __system,
  __customer
  // __team
]
function createMenuData() {
  // 在此做权限管理
  return routerConfig
}
const menuData = createMenuData()
let firstRouter = ''
const navStr = localStorage.getItem('navList')
if (navStr) {
  let list = JSON.parse(navStr) || []
  if (list.length > 0) {
    if (routerConfig[0].children) {
      firstRouter = list[0].path + '/' + list[0].children[0].path
    } else {
      firstRouter = list[0].path
    }
  } else {
    firstRouter = '/login'
  }
} else {
  if (routerConfig.length > 0) {
    if (routerConfig[0].children) {
      firstRouter = routerConfig[0].path + '/' + routerConfig[0].children[0].path
    } else {
      firstRouter = routerConfig[0].path
    }
  } else {
    firstRouter = '/login'
  }
}
export { firstRouter, menuData }
export default routerConfig
