export default {
  path: `user-manage`,
  component: () => import('@/views/user-manage'),
  meta: {
    title: '用户管理'
  },
  children: [
    {
      path: `user-list`,
      component: () => import('@/views/user-manage/user-list'),
      meta: {
        title: '用户列表'
      }
    },
    {
      path: `user-detail`,
      component: () => import('@/views/user-manage/user-detail'),
      meta: {
        title: '用户详情',
        hiddenMenu: true
      }
    },
    {
      path: `legrunner`,
      component: () => import('@/views/user-manage/legrunner/index.vue'),
      meta: {
        title: '社区助理'
      }
    },
    {
      path: `legrunner-detail`,
      component: () => import('@/views/user-manage/legrunner/legrunner-detail.vue'),
      meta: {
        title: '社区助理详情',
        hiddenMenu: true
      }
    }
  ]
}
