export default {
    path: `system`,
    component: () => import("@/views/system"),
    meta: {
        title: '权限管理',
    },
    children: [
        {
            path: `user`,
            component: () => import("@/views/system/user"),
            meta: {
                title: '用户管理'
            }
        },
        {
            path: `menu`,
            component: () => import("@/views/system/menu"),
            meta: {
                title: '菜单管理',

            }
        },
        {
            path: `role`,
            component: () => import("@/views/system/role"),
            meta: {
                title: '角色管理',

            }
        },
        {
            path: `dept`,
            component: () => import("@/views/system/dept"),
            meta: {
                title: '部门管理',

            }
        },
        {
            path: `post`,
            component: () => import("@/views/system/post"),
            meta: {
                title: '岗位管理',

            }
        },
    ]
}