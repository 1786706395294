<template>
  <div class="router-navs flex-ali-center" v-if="state.routeList.length">
    <div class="move-btn move-left flex-ali-center" @click="scrollLeft">
      <el-icon>
        <ArrowLeftBold />
      </el-icon>
    </div>
    <div class="flex-1 flex-ali-center scroll-nav" ref="scrollNavRef">
      <div class="nav-btn flex-ali-center" :class="activeIndex === index && 'active'"
        v-for="(item, index) in state.routeList" :key="index" @click="toPage(item)" @dblclick="closeRoute(index)">
        <div class="nav-circle" v-if="activeIndex === index"></div>
        {{ item.meta.title }}
        <el-icon @click.stop="closeRoute(index)" class="el-icon-error err-close">
          <CircleClose />
        </el-icon>
      </div>
    </div>
    <div class="move-btn move-right flex-ali-center" @click="scrollRight">
      <el-icon>
        <ArrowRightBold />
      </el-icon>
    </div>
  </div>
</template>

<script setup name='AcRouterNavs'>
import { name } from "../../package.json";
import { ref, reactive, onMounted, computed, watch } from 'vue'
import { useRouter, useRoute } from "vue-router";
import { CircleClose, ArrowLeftBold, ArrowRightBold } from "@element-plus/icons-vue";

const route = useRoute();

const router = useRouter();

let state = reactive({
  routeList: [],
  scrollSteps: 30,
})
// const navStr = sessionStorage.getItem('routeNavList')
// if (navStr) {
//   state.routeList = JSON.parse(navStr)
// }
let  scrollNavRef = ref(null)
onMounted(() => {
})
watch(() => router.currentRoute, (route) => {
  if (state.routeList.findIndex((item) => item.path === route.value.path) === -1) {
    state.routeList.push(route.value)
  }
  // sessionStorage.setItem('routeNavList', JSON.stringify(state.routeList))
}, {
  immediate: true,
  deep: true,
})
const activeIndex = computed(() => {
  return state.routeList.findIndex((item) => item.path === route.path)
})
const scrollLeft = () => {
  let scrollNav = scrollNavRef.value;
  let offsetWidth = scrollNav.offsetWidth;
  let scrollLeft = scrollNav.scrollLeft;
  let target = scrollLeft - offsetWidth;
  let stepWidth = offsetWidth / state.scrollSteps;
  scrollToLeftTarget(scrollNav, target, scrollLeft, stepWidth);
}
const scrollRight = () => {
  let scrollNav = scrollNavRef.value
  let offsetWidth = scrollNav.offsetWidth;
  let scrollLeft = scrollNav.scrollLeft;
  let target = offsetWidth + scrollLeft;
  let stepWidth = offsetWidth / state.scrollSteps;
  scrollToLeftTarget(scrollNav, target, scrollLeft, stepWidth);

}
const scrollToRightTarget = (el, target, oldScrollLeft, stepWidth) => {
  el.scrollTo(oldScrollLeft + stepWidth, 0);
  if (el.scrollLeft < target && el.scrollLeft !== oldScrollLeft) {
    oldScrollLeft = el.scrollLeft;
    requestAnimationFrame(() => {
      scrollToRightTarget(el, target, oldScrollLeft, stepWidth);
    });
  } else {
    el.scrollTo(target, 0);
  }
}
const scrollToLeftTarget = (el, target, oldScrollLeft, stepWidth) => {
  el.scrollTo(oldScrollLeft - stepWidth, 0);
  if (el.scrollLeft > target && el.scrollLeft !== oldScrollLeft) {
    oldScrollLeft = el.scrollLeft;
    requestAnimationFrame(() => {
      scrollToLeftTarget(el, target, oldScrollLeft, stepWidth);
    });
  } else {
    el.scrollTo(target, 0);
  }
}
const closeRoute = (index) => {
  if (state.routeList.length === 1) return;
  state.routeList.splice(index, 1);
  if (state.routeList.length) {
    router.push(
      state.routeList[state.routeList.length - 1].path.replace(
        new RegExp(`\\/subsystem\\/${name}`),
        ""
      )
    );
  }
}
const toPage = (item) => {
  router.push(
    item.fullPath.replace(new RegExp(`\\/subsystem\\/${name}`), "")
  );
}

</script>

<style scoped lang="scss">
$borderColor: #efefef;
$navHeight: 36px;

.router-navs {
  font-size: 13px;
  border-bottom: 2px solid $borderColor;
  background-color: #fafafa;
  height: $navHeight;
}

.scroll-nav {
  overflow-x: auto;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }
}

.nav-btn {
  padding: 0 15px;
  line-height: $navHeight;
  border-right: 2px solid $borderColor;
  cursor: pointer;
  white-space: nowrap;
  font-size: 13px;
  color: #666666;

  &.active {
    background-color: $borderColor;
  }

  &:hover {
    background-color: #e4effa;
  }

  .err-close {
    color: #cccccc;
    margin-left: 5px;

    &:hover {
      color: #dc0d0d;
    }
  }
}

.move-btn {
  padding: 0 10px;
  height: $navHeight;
  border-right: 1px solid $borderColor;
  cursor: pointer;

  &.move-left {
    border-right: 1px solid $borderColor;
    box-shadow: 5px -2px 12px 0 rgba(0, 0, 0, 0.1);
  }

  &.move-right {
    border-left: 1px solid $borderColor;
    box-shadow: -5px -2px 12px 0 rgba(0, 0, 0, 0.1);
  }

  &:hover {
    background-color: #e4effa;
  }
}

.ward-color {
  color: #666666;
}

.nav-circle {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  margin-right: 5px;
  background-color: var(--primary-color);
}</style>
