import commonAxios from '@/utils/request'
import {
  addDateRange,
  handleTree,
  parseTime,
  resetForm,
  selectDictLabel,
  selectDictLabels
} from '@/utils/ruoyi'
import { createApp } from 'vue'
import App from './App.vue'

import '@/assets/less/public.less'
import tableCom from '@/components/tableCom.vue'
import directives from '@/directives'
import ElementPlus from 'element-plus'
import 'element-plus/theme-chalk/index.css'
import './assets/scss/public.scss'
import router from './router'
import store from './store/index'

import * as ElementPlusIconsVue from '@element-plus/icons-vue'

const app = createApp(App)
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
  app.component(key, component)
}
directives(app)
app.component('table-Com', tableCom)
app.config.warnHandler = () => null
app.config.globalProperties.handleTree = handleTree
app.config.globalProperties.parseTime = parseTime
app.config.globalProperties.resetForm = resetForm
app.config.globalProperties.addDateRange = addDateRange
app.config.globalProperties.selectDictLabel = selectDictLabel
app.config.globalProperties.selectDictLabels = selectDictLabels

app.use(router).use(commonAxios).use(ElementPlus).use(store).mount('#app')
