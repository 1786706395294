import { firstRouter } from '@/router/router-config'
import Layout from '@/views/layout'
import { createRouter, createWebHashHistory } from 'vue-router'
let redirect = process.env.NODE_ENV === 'development' ? firstRouter : firstRouter

const routes = [
  {
    path: '/login',
    name: 'login',
    component: () => import('@/views/login')
  },
  {
    path: '/redemption-detail',
    name: 'redemption-detail',
    component: () => import('@/views/business-manage/exchange-coupons/redemption-detail')
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes: routes
})

let registerRouteFresh = true
router.beforeEach((to, from, next) => {
  let token = sessionStorage.getItem('token')
  if (to.name == 'login' && token) {
    next(false)
  }

  if (registerRouteFresh) {
    let hasData = getRouterList()
    if (hasData && hasData.children.length) {
      router.addRoute(hasData)
      next({ ...to, replace: true })
      registerRouteFresh = false
    }
  }
  if (to.name != 'login' && !token) {
    next('/login')
  } else {
    if (to.matched.length === 0) {
      next('/404')
    } else {
      next()
    }
  }
})

const getRouterList = () => {
  let routerList = localStorage.getItem('navList')
  const routerVoList = routerList ? JSON.parse(routerList) : []
  if (!routerVoList.length) {
    return
  }
  let routerObj = {
    path: '/',
    component: Layout,
    // redirect: 'login',
    children: [
      {
        path: '/404',
        name: '404',
        component: () => import('@/views/noPage')
      }
    ]
  }
  routerObj.children.unshift(...getDataCom(routerVoList))
  let list = routerObj.children
  let r = ''
  if (list[0].children) {
    r = list[0].children[0].path
  } else {
    r = list[0].path
  }
  routerObj.redirect = r
  return routerObj
}
// 递归
const getDataCom = (data, urlPre) => {
  let arr = []
  data.forEach(item => {
    let hasData = item.path[0] == '/'
    let hasDataCom = item.component[0] == '/'
    let path = hasData ? item.path : '/' + item.path
    let urlCom = () =>
      import(`@/views${hasDataCom ? item.component : '/' + item.component}`)
        ? import(`@/views${hasDataCom ? item.component : '/' + item.component}`)
        : () => import('@/views/noPage')
    let newItem = {
      ...item,
      path: urlPre ? `${urlPre}${path}` : path,
      component: item.component == 'Layout' ? '' : urlCom,
      children: item.children && item.children.length ? getDataCom(item.children, item.path) : []
    }
    arr.push(newItem)
  })
  return arr
}

export default router
