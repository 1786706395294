export default {
  path: `customer`,
  component: () => import('@/views/customer/index'),
  meta: {
    title: '客服中心'
  },
  children: [
    {
      path: `customer`,
      component: () => import('@/views/customer/customer/index.vue'),
      meta: {
        title: '客服中心'
      }
    }
  ]
}
