<template>
  <div id="app">
    <el-config-provider :locale="locale">
      <router-view />
    </el-config-provider>
  </div>
</template>

<script setup>
import { onMounted, reactive } from 'vue'
import zhCn from 'element-plus/lib/locale/lang/zh-cn';
const { locale } = reactive({
  locale: zhCn,
});


</script>

<style></style>
