<template>
  <el-table
    ref="dataTable"
    :data="props.data"
    height="100%"
    @cell-dblclick="copyText"
    :row-class-name="rowClassName"
    border
  >
    <slot></slot>
  </el-table>
</template>
<script setup>
import { copyText } from '@/utils/index'
import { defineProps } from 'vue'
let props = defineProps(['data'])
const rowClassName = ({ rowIndex }) => {
  return rowIndex % 2 === 0 ? 'even-row' : 'odd-row'
}
</script>
<style lang="scss">
.even-row td {
  background-color: #fff !important; /* 偶数行背景色 */
}

.odd-row td {
  background-color: rgb(247 247 247) !important; /* 奇数行背景色 */
}
</style>
